import React from 'react';
import { graphql, PageProps } from 'gatsby';
import algoliasearch from 'algoliasearch/lite';
import { ArrowRight } from '@material-ui/icons';
import { Configure, Hits, InstantSearch, Stats } from 'react-instantsearch-dom';
import {
  ContentfulComponentSiteFooter,
  ContentfulComponentSiteHeader,
  SearchPageQuery,
} from '../../../graphql-types';
import Layout from '../../components/Layout';
import { PageContext } from '../../types/pageContext';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import SEO from '../../components/SEO';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import Typography from '../../components/Typography';
import { WEBSITE } from '../../types/website.enum';
import edgepointTheme from '../../themes/edgepoint';
import cymbriaTheme from '../../themes/cymbria';
import { useGlobalState } from '../../hooks/useGlobalState';
import { getTranslation } from '../../services/translation.service';
import CookiePreferences from '../../components/CookiePreferences';
import { checkSegmentInURL } from '../../utils/urlUtils';

const Hit = ({ hit }) => {
  return (
    <LocalizedLink
      language={hit.node_locale}
      to={`/${hit.slug}/`}
      className="flex flex-col lg:w-3/4 border-l border-primary mt-s4 pl-s2 no-underline"
    >
      <Typography as="h2" variant="h4" className="align-middle">
        <span>{hit.title}</span>
        <ArrowRight />
      </Typography>
      <Typography as="div" variant="body" className="mt-s1">
        {hit.description || 'No description'}
      </Typography>
    </LocalizedLink>
  );
};

export const query = graphql`
  query SubsiteSearchPage(
    $globalWebsiteContentfulId: String!
    $node_locale: String!
  ) {
    contentfulGlobalWebsite(
      contentful_id: { eq: $globalWebsiteContentfulId }
      node_locale: { eq: $node_locale }
    ) {
      ...WebsiteFragment
      homePage {
        seo {
          ...ContentfulComposeSeoFragment
        }
      }
    }
  }
`;

interface SearchLocationState {
  query?: string;
}

const Page: React.FC<
  PageProps<SearchPageQuery, PageContext, SearchLocationState>
> = (props) => {
  const { data, location, subsiteSlug } = props;
  const { contentfulGlobalWebsite } = data;
  const { locale, config } = useLocalization();
  const { site } = useGlobalState();
  const [searchQuery, setSearchQuery] = React.useState<string | null>(null);

  const cookiePreferences = contentfulGlobalWebsite?.cookiePreferences;

  const subSiteEntryPages = contentfulGlobalWebsite?.subsiteEntryPage;

  //* Check if the current page is a child of a subsite entry page
  let currentSubSite;
  if (subSiteEntryPages && subSiteEntryPages?.length > 0) {
    subSiteEntryPages.forEach((subSiteEntryPage) => {
      if (checkSegmentInURL(location.href, subsiteSlug)) {
        currentSubSite = subSiteEntryPage;
      }
    });
  }

  //* Get the header, footer, and SEO data for the current subsite otherwise default to the global website header/footer/seo
  const searchHeader =
    currentSubSite?.pageHeader ?? contentfulGlobalWebsite?.header;

  const searchSEO =
    currentSubSite?.seoMetadata ?? contentfulGlobalWebsite?.homePage.seo;

  const searchFooter =
    currentSubSite?.pageFooter ?? contentfulGlobalWebsite?.footer;

  const filters = `locale:"${
    config.find((c) => c.code === locale)?.hrefLang
  }" AND website:"${
    contentfulGlobalWebsite.title
  }" AND subSites:"${subsiteSlug}"`;

  // remove empty queries
  // ref: https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/js/
  const algoliaClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID || '',
    process.env.GATSBY_ALGOLIA_SEARCH_KEY || '',
  );

  const searchClient = {
    ...algoliaClient,
    search(requests) {
      // prevent empty queries
      if (requests.every(({ params }) => !params.query)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
          })),
        });
      }

      return algoliaClient.search(requests);
    },
  };

  const searchIndexName = process.env.GATSBY_ALGOLIA_INDEX_NAME;

  // update query when location updates
  React.useEffect(() => {
    if (location?.state?.query) {
      setSearchQuery(location.state.query);
    }
  }, [location]);

  const forString = getTranslation('For', locale);
  const sorryString = getTranslation('WeAreSorry', locale, searchQuery ?? '');

  return (
    <Layout theme={site === WEBSITE.EDGEPOINT ? edgepointTheme : cymbriaTheme}>
      <SEO data={searchSEO} location={location} />
      <Header
        data={searchHeader as unknown as ContentfulComponentSiteHeader}
        location={location}
        logo={contentfulGlobalWebsite?.siteAssets?.logo}
        mobileLogo={contentfulGlobalWebsite?.siteAssets?.mobileLogo}
        subsite={currentSubSite}
      />
      <main id="main" className="border-t-2 border-primary">
        <div className="container pb-s3">
          <InstantSearch
            searchClient={searchClient}
            indexName={searchIndexName}
          >
            <Configure query={searchQuery} filters={filters} distinct />
            {searchQuery ? (
              <Typography as="div" variant="body" className="mt-s3">
                <Stats className="inline" /> {forString} <em>{searchQuery}</em>
              </Typography>
            ) : (
              <Typography as="div" variant="body" className="mt-s3">
                <em>{sorryString}</em>
              </Typography>
            )}
            <Hits hitComponent={Hit} />
          </InstantSearch>
        </div>
      </main>
      <Footer
        data={searchFooter as unknown as ContentfulComponentSiteFooter}
        logo={contentfulGlobalWebsite?.siteAssets?.logo}
        socialLinks={contentfulGlobalWebsite?.siteAssets?.socialLinks}
      />

      {cookiePreferences && (
        <CookiePreferences
          id={cookiePreferences?.id}
          title={cookiePreferences?.title}
          description={cookiePreferences?.description}
          manageConsentPreferencesTitle={
            cookiePreferences?.manageConsentPreferencesTitle
          }
          cookieDuration={cookiePreferences?.cookieDuration}
          cookies={cookiePreferences?.cookies}
          acceptAllCookiesButton={cookiePreferences?.acceptAllCookiesButton}
          rejectAllCookiesButton={cookiePreferences?.rejectAllCookiesButton}
          manageCookiePreferencesButton={
            cookiePreferences?.manageCookiePreferencesButton
          }
          acceptChoosenPreferencesButton={
            cookiePreferences?.acceptChoosenPreferencesButton
          }
          location={location}
        />
      )}
    </Layout>
  );
};

export default Page;
